<template>
  <!-- 学员管理页面-->
  <div class="teachManage">
    <div class="teachManage-tool">
      <div class="flex">
        <!-- <div class="f-l f-c">
          <div class="f-l teachManage-tool-title">
            {{
              $t("el.infrastructure.teacherNameMobile") + $t("el.common.colon")
            }}
          </div>
          <el-input
            class="f-l"
            v-model="form.staffCode"
            :placeholder="$t('el.common.pleaseEnter')"
            size="small"
            style="width: 180px; margin-right: 30px"
          ></el-input>
        </div> -->
        <xm-form
          ref="form"
          class="class-form flex flex-m flex-w"
          :form-items="formItems"
          :form-options="newFormOptions"
          :form-data="queryParams"
          @submit.native.prevent
        >
        </xm-form>
      </div>
      <div class="flex">
        <button style="width: 65px" @click="getData">
          <!-- <img
            src="@/assets/images/icon/icon_search.png"
            class="teachManage-tool-btnicon"
          /> -->
          <span>{{ $t("el.common.search") }}</span>
        </button>
        <button style="width: 65px; margin-left: 10px" @click="resetForm">
          <!-- <img
            src="@/assets/images/icon/icon_search.png"
            class="teachManage-tool-btnicon"
          /> -->
          <span>{{ $t("el.common.reset") }}</span>
        </button>
        <!-- <button
          style="
            width: fit-content;
            padding-left: 12px;
            padding-right: 12px;
            margin-left: 10px;
          "
          @click="handleAddTeacher"
        >
          <span>{{ $t("el.saas.addTeacher") }}</span>
        </button> -->
        <button
          style="
            width: fit-content;
            padding-left: 12px;
            padding-right: 12px;
            margin-left: 10px;
          "
          class="plain"
          @click="batchUserPermission"
        >
          <span>{{ $t("el.infrastructure.BatchConfiguration") }}</span>
        </button>
        <button
          style="
            width: fit-content;
            padding-left: 12px;
            padding-right: 12px;
            margin-left: 10px;
          "
          class="plain"
          @click="handleDownloadTemplate"
        >
          <span>{{ $t("el.saas.downloadTemplate") }}</span>
        </button>
        <el-upload
          class="upload-demo"
          :action="baseUrl + '/teachManage/import'"
          :multiple="false"
          :show-file-list="false"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :headers="myHeaders"
          :before-upload="beforeUpload"
          :on-success="handleSuccess"
          :on-error="handleError"
        >
          <button
            style="
              width: fit-content;
              padding-left: 12px;
              padding-right: 12px;
              margin-left: 10px;
            "
            class="plain"
          >
            <span>{{ $t("el.saas.batchImport") }}</span>
          </button>
        </el-upload>
      </div>
    </div>
    <!-- 表格 -->
    <div class="teachManage-table">
      <el-table
        ref="userManageTable"
        v-loading="loading"
        :row-key="getRowKeys"
        :data="tableData"
        border
        :header-cell-class-name="headerStyle"
        :cell-class-name="cellStyle"
        style="width: 100%"
        @selection-change="(value) => (selections = value)"
      >
        <template slot="empty">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noPerson") }}
          </div>
        </template>
        <!-- <el-table-column
          prop="staffCode"
          :label="$t('el.schoolResourceManage.workNumber')"
          :show-overflow-tooltip="true"
          min-width="10%"
        >
        </el-table-column> -->
        <el-table-column
          type="selection"
          :reserve-selection="true"
          :selectable="checkboxSelect"
          label="全选"
        ></el-table-column>
        <el-table-column
          prop="staffName"
          :label="$t('el.authDialog.name')"
          :show-overflow-tooltip="true"
          min-width="10%"
        >
        </el-table-column>
        <el-table-column prop="status" :label="$t('状态')" min-width="10%">
          <template slot-scope="scope">
            <div>
              {{
                statusOptions.find((item) => item.value == scope.row.status)
                  ?.label
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          :show-overflow-tooltip="true"
          :label="$t('el.infrastructure.mobilePhoneNumber')"
          min-width="14%"
        >
        </el-table-column>
        <el-table-column
          prop="idNumber"
          :show-overflow-tooltip="true"
          :label="$t('el.infrastructure.idNumber')"
          min-width="14%"
        >
        </el-table-column>
        <el-table-column
          prop="manageClassName"
          :show-overflow-tooltip="true"
          :label="$t('el.common.TeachingClass')"
          min-width="13%"
        >
        </el-table-column>
        <el-table-column
          prop="download"
          :show-overflow-tooltip="true"
          :label="$t('el.common.resourceUse')"
          min-width="12%"
        >
          <template slot-scope="scope">
            <div>
              {{ formatDownloadText(scope.row) }}
            </div>
          </template>
        </el-table-column>
        <!-- TODO: eduAuth功能权限？ -->
        <el-table-column
          prop="roleName"
          :show-overflow-tooltip="true"
          :label="$t('el.infrastructure.functionalAuthority')"
          min-width="18%"
        >
        </el-table-column>
        <!-- <el-table-column prop="subjectAuthList"
                         :show-overflow-tooltip="true"
                         label="科目权限"
                         min-width="11%">
          <template slot-scope="scope">
            <div>{{scope.row.subjectAuthList&&scope.row.subjectAuthList.length>0?getListLabel(scope.row.subjectAuthList,'subjectName'):''}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="gradeAuthList"
                         :show-overflow-tooltip="true"
                         label="年级权限"
                         min-width="19%">
          <template slot-scope="scope">
            <div>{{scope.row.gradeAuthList&&scope.row.gradeAuthList.length>0?getListLabel(scope.row.gradeAuthList,'gradeName'):''}}</div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="authCourseNames"
          :label="$t('el.infrastructure.coursePermissions')"
          min-width="30%"
        >
          <template slot-scope="scope">
            <!-- <tooltip-over
              :content="
                scope.row.authCourseNames &&
                scope.row.authCourseNames.length > 0
                  ? scope.row.authCourseNames.toString()
                  : ''
              "
              :singleAble="true"
            ></tooltip-over> -->
            <tooltip-over
              :content="
                scope.row.authCourseNames &&
                scope.row.authCourseNames.join('、')
              "
              :singleAble="true"
            ></tooltip-over>
            <!-- <span
              v-for="(item, index) in scope.row.authCourseNames"
              :key="index"
            >
              {{ item }}
              <span v-if="index != scope.row.authCourseNames.length - 1"
                >、</span
              >
            </span> -->
            <!-- <div>{{scope.row.authCourseNames&&scope.row.authCourseNames.length>0?scope.row.authCourseNames.toString():''}}</div> -->
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="upload"
          :show-overflow-tooltip="true"
          :label="'是否允许 \n 上传个人资源'"
          min-width="11%"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.upload == 0 ? "是" : "否" }}</div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="upload"
          :show-overflow-tooltip="true"
          label="状态"
          min-width="11%"
        >
          <template slot-scope="scope">
            <div>{{ ~~scope.row.isShow === 0 ? "启用" : "禁用" }}</div>
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('el.common.operate')" min-width="14%">
          <template slot-scope="scope">
            <el-button type="text" @click="toAuthorize(scope.row)">{{
              $t("el.infrastructure.authorization")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页  -->
    <div class="teachManage-page" v-show="tableData.length > 0">
      <el-pagination
        layout="prev, pager, next"
        :current-page.sync="params.page"
        :page-size="params.limit"
        :total="total"
      >
      </el-pagination>
      <!-- <span class="el-pagination__total"
        >{{ $t("el.pagination.totalname") }}{{ Math.ceil(total / params.limit)
        }}{{ $t("el.pagination.pages") }} {{ total
        }}{{ $t("el.pagination.items") }}
      </span> -->
    </div>
    <!-- 导入的loading -->
    <BatchImportDialog
      :dialogVisible="batchImportVisible"
      :loading="batchImportLoading"
    ></BatchImportDialog>
    <!-- 导入结果显示弹窗 -->
    <import-result
      v-if="batchImportResultVisible"
      :dialogVisible="batchImportResultVisible"
      :dialog-title="$t('el.planCourse.importResult')"
      :dialog-text-content="{
        text: partiallySuccess.text,
      }"
      :confirmText="confirmText"
      :excelResultHref="partiallySuccess.url"
      customClass="teacher-import-result"
      @handleClose="handleImportResultClose"
      @handleConfirm="handleImportResultClose"
    />
  </div>
</template>

<script>
import { windowOpen } from "@/utils";
import { mapGetters, mapActions } from "vuex";
import { getTableListNew, schoolTree, classTree } from "@/api/home/teachmanage";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
// import { startOfDay, endOfDay, subDays, format } from "date-fns";
import { handparams } from "../../../utils";
import { getToken } from "@/core/auth";
import BatchImportDialog from "./components/BatchImportDialog.vue";
import { getRoleListApi } from "@/api/manage/role-manage";
import ImportResult from "@/components/import-tools/import-result.vue";
export default {
  name: "teachManage",
  // 注册组件
  components: {
    tooltipOver,
    BatchImportDialog,
    ImportResult,
  },
  // 定义数据
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      loading: false, // 列表加载状态
      schoolList: [],
      tableData: [],
      queryParams: {
        staffName: "",
        mobile: "",
        schoolCodes: [],
        classIds: [],
        schoolCode: "",
        classId: "",
        gradeId: "",
        downloads: "",
        upload: "",
        download: "",
        eduAuth: "",
        authCourseName: "",
      }, // 查询参数
      newFormOptions: {},
      params: {
        // 分页
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0,
      schoolOptions: [], // 学校学部 options
      classOptions: [], // 授课班级 options
      selections: [],
      nowSchoolCode: 0, // 当前学校学部
      maxLength: 0,
      batchImportVisible: false,
      batchImportLoading: false,
      bacthImportMessage: "",
      myHeaders: { Authorization: getToken() },
      roleOptions: [],
      batchImportResultVisible: false,
      partiallySuccess: {
        url: "",
        text: "",
      },
      statusOptions: [
        { label: "正常", value: 1 },
        { label: "停用", value: 3 },
      ],
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(["eduAuth", "attendSchoolList"]),
    confirmText() {
      return this.$t("el.planCourse.downloadToView");
    },
    downSelectList() {
      return [
        {
          value: 0,
          label: this.$t("el.common.AllowUpload"),
        },
        {
          value: 1,
          label: this.$t("el.common.AllowDownload"),
        },
        {
          value: 2,
          label: this.$t("el.common.AllowCopy"),
        },
      ];
    },
    eduAuthList() {
      return [
        {
          value: 0,
          label: this.$t("el.infrastructure.EducationalAdministrators"),
        },
        {
          value: 1,
          label: this.$t("el.infrastructure.educationalAdministrator"),
        },
        {
          value: 2,
          label: this.$t("el.infrastructure.averageUser"),
        },
        {
          value: 3,
          label: this.$t("el.infrastructure.Regulator"),
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "input",
          prop: "staffName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.authDialog.name"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "mobile",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.infrastructure.mobilePhoneNumber"),

          clearable: true,
        },
        // {
        //   itemType: "select",
        //   prop: "schoolCode",
        //   size: "small",
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.MyHome.depart"),
        //   "popper-append-to-body": false,
        //   clearable: true,
        //   filterable: true,
        //   // props: {
        //   // value: "schoolCode",
        //   // label: "schoolName",
        //   // children: "children",
        //   // filterable: true,
        //   // multiple: true,
        //   // },
        //   options: this.schoolList,
        // },
        {
          itemType: "input",
          prop: "manageClassName",
          size: "small",
          // options: this.classOptions,
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.common.TeachingClass"),
          clearable: true,
          "popper-append-to-body": false,
          // props: {
          //   value: "code",
          //   label: "name",
          //   children: "children",
          //   // multiple: true,
          // },
        },
        {
          itemType: "select",
          prop: "downloads",
          size: "small",
          multiple: true,
          options: this.downSelectList,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.common.resourceUse"),
          clearable: true,
        },
        {
          itemType: "select",
          prop: "status",
          size: "small",
          options: this.statusOptions,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: "状态",
          clearable: true,
        },
        {
          itemType: "select",
          prop: "roleId",
          size: "small",
          options: this.roleOptions,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.infrastructure.functionalAuthority"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "authCourseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.CourseName"),

          clearable: true,
        },
        {
          itemType: "daterange",
          prop: "daterangetime",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.expirationDate"),
          prop1: "startTime",
          prop2: "endTime",
          // "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd",
          clearable: true,
        },
      ];
    },
  },
  beforeRouteEnter(to, from, next) {
    // 用来判断是否缓存
    if (from.name !== "authorize" && from.name !== "batchUserPermission") {
      to.meta.isBack = false;
    } else {
      to.meta.isBack = false;
    }

    next();
  },
  activated() {
    if (!this.$route.meta.isBack) {
      this.$refs.userManageTable && this.$refs.userManageTable.clearSelection();
      this.init();
      this.getData();
    } else {
      this.getData();
    }
    this.fetchQrCode({ source: "teachManage" });
  },
  // 侦听器
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      this.params.offset = this.params.limit * (this.params.page - 1);
      this.getData();
    },
    "queryParams.schoolCode": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.queryParams.schoolCode = newVal;
          this.getClassTree(this.queryParams.schoolCode);
        }
      },
      immediate: true,
      deep: true,
    },
    "queryParams.classIds": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.queryParams.gradeId = newVal[0];
          this.queryParams.classId = newVal[1];
        }
      },
      immediate: true,
      deep: true,
    },
    "queryParams.downloads"(newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        if (newVal.indexOf(0) > -1) {
          this.queryParams.upload = 0;
        } else {
          this.queryParams.upload = "";
        }
        if (newVal.indexOf(1) > -1) {
          this.queryParams.download = 0;
        } else {
          this.queryParams.download = "";
        }
        if (newVal.indexOf(2) > -1) {
          this.queryParams.copy = 0;
        } else {
          this.queryParams.copy = "";
        }
      } else {
        delete this.queryParams.upload;
        delete this.queryParams.download;
        delete this.queryParams.copy;
      }
      // if (newVal) {
      //   switch (newVal) {
      //     case "0":
      //       this.queryParams.upload = 0;
      //       this.queryParams.download = 0;
      //       break;
      //     case "1":
      //       this.queryParams.upload = 0;
      //       this.queryParams.download = 1;
      //       break;
      //     case "2":
      //       this.queryParams.upload = 1;
      //       this.queryParams.download = 0;
      //       break;
      //     case "3":
      //       this.queryParams.upload = 1;
      //       this.queryParams.download = 1;
      //       break;

      //     default:
      //       break;
      //   }

      //   this.queryParams.classId = newVal[1];
      // } else {
      //   delete this.queryParams.upload;
      //   delete this.queryParams.download;
      // }
    },
  },
  // 创建完成钩子
  async created() {
    // this.getSchoolTree();
    this.handleGetRoleList();
    this.getData();
    // this.getAuthSchoolList();
  },
  // 方法定义
  methods: {
    ...mapActions(["fetchQrCode"]),
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    getRowKeys(row) {
      return row.userId;
    },
    init() {
      Object.assign(this.$data, this.$options.data());
      localStorage.setItem("batchSelectUsersFront", []);
      localStorage.setItem("batchSelectUserIdsFront", []);
      // this.getAuthSchoolList();
    },
    formatDownloadText(value) {
      let label = "";
      if (value.upload === 0) {
        label = this.$t("el.common.AllowUpload") + "、";
      }
      if (value.download === 0) {
        label = label + this.$t("el.common.AllowDownload") + "、";
      }
      if (value.copy === 0) {
        label = label + this.$t("el.common.AllowCopy") + "、";
      }
      label && (label = label.slice(0, label.length - 1));
      return label;
    },
    // 获取学校列表
    async getAuthSchoolList() {
      await this.$store.dispatch("getSchoolList");
      this.schoolList = this.attendSchoolList;
      this.$nextTick(() => {
        if (this.schoolList.length > 0) {
          this.getData();
        } else {
          this.maxLength = 0;
        }
      });
      return this.attendSchoolList;
    },
    /**
     * 重置
     */
    resetForm() {
      this.$refs["form"].resetForm();
      this.params = {
        // 分页
        limit: 10,
        offset: 0,
        page: 1,
      };
      this.$nextTick(() => {
        this.getData();
      });
    },
    // 获取授课班级选项
    getClassTree(schoolCode) {
      return classTree(schoolCode)
        .then(({ data }) => {
          this.classOptions = data || [];
        })
        .catch(() => {
          this.classOptions = [];
        });
    },
    getSchoolTree() {
      // 获取学校选项
      schoolTree().then(({ data }) => {
        this.schoolOptions = data;
      });
    },
    sureClass() {
      var check = "";
      check = this.$utils.check(this.formdata, [
        { key: "name", require: true, msg: "name" },
      ]);
      if (check === 1) {
        for (var i in this.ischeck) {
          this.ischeck[i] = false;
        }
      } else {
        this.ischeck[check] = true;
      }
    },
    // 多选禁用
    checkboxSelect(row, rowIndex) {
      if (
        row.eduAuth !== undefined &&
        (~~row.eduAuth === 0 || (~~this.eduAuth !== 0 && ~~row.eduAuth === 1))
      ) {
        return false; // 禁用
      } else {
        return true; // 不禁用
      }
    },
    // 授权
    toAuthorize(row) {
      this.$router.push({
        name: "authorize",
        query: {
          id: row.userId,
        },
      });
    },
    getData() {
      this.loading = true;
      let params = { ...this.params, ...this.queryParams };

      params = handparams(params);
      if (!params.daterangetime || params.daterangetime.length !== 2) {
        params.startTime = params.endTime = "";
      }
      delete params.daterangetime;
      delete params.downloads;
      // if (params.eduAuth === 2) {
      //   params.eduAuth = "";
      // }
      getTableListNew(handparams(params))
        .then(({ data }) => {
          this.tableData = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getListLabel(arr, name) {
      var newarr = [];
      arr.forEach((element) => {
        newarr.push(element[name]);
      });
      newarr = newarr.join(",");
      return newarr;
    },

    // 批量配置
    batchUserPermission() {
      let selections = this.selections;
      if (selections && selections.length > 0) {
        let selectUsers = selections
          .map((item) => {
            return item.staffName;
          })
          .join(",");
        let selectUserId = selections.map((item) => {
          return item.userId;
        });
        localStorage.setItem("batchSelectUsersFront", selectUsers);
        localStorage.setItem("batchSelectUserIdsFront", selectUserId);
        this.$router.push({
          name: "batchUserPermission",
        });
      } else {
        this.$message.warning("请先选择用户！");
      }
    },

    // 添加教师
    handleAddTeacher() {
      this.$router.push({
        name: "authorize",
      });
    },

    // 下载模板
    handleDownloadTemplate() {
      windowOpen(
        "https://hlqe-obs-public-files.obs.cn-east-3.myhuaweicloud.com/sjSaas/sjSaasApplication/aiyunketang/%E6%95%99%E5%B8%88%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%8E%88%E6%9D%83%E6%A8%A1%E6%9D%BF.xlsx" +
          `?t=${new Date().getTime()}`
      );
    },

    // 批量导入
    handleBatchImport() {
      this.batchImportVisible = true;
      console.log("点击了批量导入按钮");
    },
    // 批量导入
    beforeUpload(file) {
      this.batchImportLoading = true;
      this.batchImportVisible = true;
    },
    async handleSuccess(response) {
      this.batchImportLoading = false;
      this.batchImportVisible = false;
      if (response.success) {
        if (response.data?.flag === "ok") {
          // 全部导入成功
          this.$message({
            type: "success",
            message: response.message,
          });
        } else {
          // 部分导入成功
          this.partiallySuccess = {
            url: response.data?.fileUrl || "",
            text: response.data?.info || "",
          };
          this.batchImportResultVisible = true;
        }
        this.getData();
      } else {
        // 导入失败
        this.$message({
          type: "error",
          message: response.message,
        });
      }
    },
    handleError() {
      this.batchImportVisible = false;
    },
    handleImportResultClose() {
      this.batchImportResultVisible = false;
    },
    // 获取功能权限
    handleGetRoleList() {
      getRoleListApi().then((res) => {
        this.roleOptions = (res?.data || []).map(({ roleId, roleName }) => ({
          label: roleName,
          value: roleId,
        }));
      });
    },
  },
};
</script>

<style lang="less" scoped>
.teachManage {
  background: #fff;
  padding: 18px 0 46px 0;
  border-radius: 4px;
  /deep/ .el-table__body {
    width: 100%;
  }
  &-tool {
    margin: 20px 50px 25px 51px;
    .class-form {
      width: fit-content;
      height: fit-content;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
      display: flex;
    }
    /deep/ .el-form-item__content {
      line-height: 40px;
      position: relative;
      font-size: 14px;
      padding-bottom: 22px;
      margin-right: 10px;
    }
    button {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(249, 249, 249, 1);
      height: 32px;
      display: flex;
      background: @themeBlue;
      border-radius: 5px;
      float: left;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      outline: none;
      &.plain {
        background: #fff;
        color: @themeBlue;
        border: 1px solid @themeBlue;
      }
    }
    &-title {
      min-width: 120px;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(70, 70, 70, 1);
    }
    &-btnicon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
  }
  &-table {
    padding: 0 50px 0 51px;
    /deep/.bskHeader {
      background-color: #f5f6f7 !important;
      color: #323232;
      text-align: center !important;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      font-weight: normal;
      height: 50px;
      padding: 0;
      .cell {
        white-space: pre-line;
      }
    }
    /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #5a53f5;
      border-color: #5a53f5;
    }
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #5a53f5;
      border-color: #5a53f5;
    }
    /deep/.bskCell {
      text-align: center !important;
      color: #707276;
      font-size: 14px;
      height: 46px;
      padding: 0;
      .cell {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
        div {
          width: 100%;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          white-space: nowrap;
          display: block;
        }
      }
    }
    /deep/.el-button--text {
      border: 0;
      color: @themeBlue;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: #f1f6ff;
    }
  }

  &-page {
    padding: 39px 50px 0 51px;
    text-align: center;
    /deep/ .el-pager li {
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      padding: 0 0px;
      margin: 0 5px;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
      font-weight: 400;
      color: rgba(40, 40, 40, 1);
    }
    /deep/ .el-pager li.active {
      background: @themeBlue;
      color: #fff;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
    }
    /deep/.el-pagination__total {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(40, 40, 40, 1);
    }
    /deep/.el-pagination button {
      height: 30px;
      line-height: 30px;
    }
    /deep/ .el-pagination button:hover {
      color: @themeBlue;
    }
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
}
.teachManage-page {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
</style>
<style lang="less">
.el-tooltip__popper {
  max-width: 50%;
  line-height: 1.5;
}
.teacher-import-result {
  .el-button {
    margin-left: 14px;
  }
}
</style>
