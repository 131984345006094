<template>
  <el-dialog
    :before-close="handleClose"
    :visible.sync="dialogVisible"
    :show-close="false"
    :class="{ 'dialog-com-mini': dialogTextContent }"
    width="30%"
    :custom-class="['dialog-com', customClass]"
    append-to-body
  >
    <div slot="title" class="dialog-title">
      <span>{{ dialogTitle }}</span>
      <div v-if="dialogTitleDes" class="dialog-title-des">
        <i :class="dialogTitleStatus" class="el-icon-warning"></i>
        <span>{{ dialogTitleDes }}</span>
      </div>
    </div>
    <div v-if="dialogTextContent" class="dialog-content">
      <p>
        <i
          v-if="dialogTextContent.status"
          :class="
            dialogTextContent.status ? dialogTextContent.status : 'warning'
          "
          class="el-icon-warning"
        ></i>
        <span>{{ dialogTextContent.text }}</span>
      </p>
    </div>
    <slot name="dialog-manage-content"></slot>
    <span slot="footer" class="dialog-footer">
      <div class="dialog-footer-btn">
        <a target="_blank" :href="excelResultHref">
          <el-button type="primary" @click="handleConfirm">
            <span class="button-text">{{ confirmText }}</span>
          </el-button>
        </a>
        <!-- <el-button type="primary" @click="handleConfirm">{{
          confirmText
        }}</el-button> -->
        <el-button v-if="canCancel" @click="handleClose">关闭</el-button>
      </div>
      <div class="dialog-footer-tip" v-if="footerTip">{{ footerTip }}</div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dialogTitleStatus: {
      type: String,
      default: "warning",
    },
    dialogTitleDes: {
      type: String,
      default: "",
    },
    dialogTextContent: {
      type: Object,
      default: null,
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: "确定",
    },
    footerTip: {
      type: String,
      default: "",
    },
    excelResultHref: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    handleConfirm() {
      this.$emit("handleConfirm");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less">
.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-dialog {
    margin-top: 0 !important;
  }
}
.dialog-com {
  min-width: 730px;
  .el-dialog__header {
    padding: 20px 28px;
    // border-bottom: 1px solid #efefef;
  }
  .dialog-title {
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-title-span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background-color: @themeNewPurple;
      margin-right: 8px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #303133;
    }
    .dialog-title-des {
      margin-left: 20px;
      display: flex;
      align-items: center;
      i {
        font-size: 15px;
      }
      .warning {
        color: #f8b62d;
      }
      span {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.46);
        margin-left: 3px;
      }
    }
  }
  .el-dialog__body {
    // min-height: 171px;
    padding: 36px 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dialog-content {
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 15px;
        }
        .warning {
          color: #f8b62d;
        }
        span {
          font-size: 14px;
          line-height: 22px;
          color: #606266;
          text-align: left;
        }
      }
    }
  }
  .el-dialog__footer {
    // border-top: 1px solid #efefef;
    padding: 15px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-button {
      min-width: 90px;
      font-size: 14px;
      height: 32px;
      text-align: center;
      color: @themeNewPurple;
      border-color: @themeNewPurple;
    }
    .el-button--primary {
      color: #fff;
      background-color: @themeNewPurple;
      a {
        color: #fff;
      }
    }
    .el-button + .el-button {
      margin-left: 18px;
    }
  }
}
.dialog-com-mini {
  .dialog-com {
    min-width: 500px;
    max-width: 500px;
  }
}
</style>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  outline: none;
  box-sizing: border-box;
}
.dialog-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .dialog-footer-tip {
    margin-top: 12px;
    font-size: 12px;
    color: #999;
  }
}
</style>
