<template>
  <div class="class-menu">
    <div class="flex flex-m mb10">
      <div
        @click="handleAllChose()"
        :class="
          allChose
            ? 'color-chose-all item-bg-chose-all ' +
              $t('el.courseClassManage.classMenuTitleWidth')
            : 'color-no-chose-all item-bg-no-chose-all ' +
              $t('el.courseClassManage.classMenuTitleWidth')
        "
        class="class-menu-title"
      >
        {{ $t("el.courseClassManage.allStudents") }}
      </div>
      <el-button
        type="primary"
        class="teachManage-tool-btnicon"
        style="margin-left: auto"
        @click="onHandleAddClass"
        >{{ $t("el.courseClassManage.addClass") }}</el-button
      >
      <!-- <span @click="onHandleAddClass" class="class-menu-add">+ 添加班级</span> -->
    </div>
    <div class="class-menu-content" v-if="classList.length">
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="item2 in classList"
          :key="item2.id"
          :name="item2.schoolYear"
          :title="item2.schoolYear"
        >
          <div
            v-for="(item, index) in item2.contents"
            :key="item.id"
            :class="
              item.active || item.hasChose
                ? 'item-bg-chose'
                : 'item-bg-no-chose'
            "
            @mouseover="selectStyle(item)"
            @mouseout="outStyle(item)"
            @click="handleClassChose(item)"
          >
            <div class="flex class-menu-item">
              <!-- 超出悬浮，不超出不悬浮 -->
              <tooltip-over
                :content="item.className"
                class="class-menu-name"
                :class="
                  item.active || item.hasChose
                    ? 'color-chose'
                    : 'color-no-chose'
                "
                :refName="'tooltipOver' + index"
              ></tooltip-over>

              <span
                class="class-menu-count"
                :class="(item.active || item.hasChose) && 'color-chose'"
                >({{ item.thisClassNum }}/{{ item.maxStudentNum }})</span
              >
              <span v-show="item.active || item.hasChose" class="ml10">
                <i
                  @click="editItem(item, item2.schoolYear)"
                  class="el-icon-edit hover"
                ></i>
                <i
                  @click="deleteItem(item)"
                  class="el-icon-delete hover ml5"
                ></i>
              </span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import tooltipOver from "../tooltipOver/index.vue";
export default {
  name: "classTabMenu",
  props: {
    // 课程信息
    courseInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentClassId: {
      type: Number,
      default: null,
    },
    // 班级列表
    classList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    activeNamesPar: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    tooltipOver,
  },
  data() {
    return {
      allChose: true,
      activeNames: [],
    };
  },
  computed: {},
  mounted() {
    this.activeNames = this.activeNamesPar;
  },
  watch: {
    /**
     * 保存不重置项
     */
    classList: {
      handler(newVal, oldVal) {
        if (newVal instanceof Array && newVal.length > 0) {
          if (this.currentClassId) {
            newVal.forEach((element) => {
              element.contents.forEach((el) => {
                if (el.id === this.currentClassId) {
                  el.hasChose = true;
                } else {
                  el.hasChose = false;
                }
              });
            });
          } else {
            this.allChose = true;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 班级item hover
    selectStyle(item) {
      let that = this;

      this.$nextTick(() => {
        this.classList.forEach((element) => {
          element.contents.forEach((el) => {
            that.$set(el, "active", false);
          });
        });
        this.$set(item, "active", true);
      });
    },
    // 班级 item 鼠标移出
    outStyle(item) {
      this.$set(item, "active", false);
    },
    // 班级item选中
    handleClassChose(item) {
      let that = this;
      this.allChose = false;
      this.$nextTick(() => {
        this.classList.forEach((element) => {
          element.contents.forEach((el) => {
            that.$set(el, "hasChose", false);
          });
        });
        this.$set(item, "hasChose", true);
      });
      this.$emit("handle-menu-item", item);
    },
    // 全部选中
    handleAllChose() {
      let that = this;
      this.$nextTick(() => {
        this.classList.forEach((element) => {
          that.$set(element, "hasChose", false);
        });
      });
      this.allChose = true;
      this.$emit("all-chose");
    },
    // 编辑 item
    editItem(item, fullSchoolYear) {
      this.$emit("edit-menu-item", item, fullSchoolYear);
    },
    // 删除
    deleteItem(item) {
      this.$emit("delete-menu-item", item);
    },
    // 新增班级
    onHandleAddClass() {
      this.$emit("add-class");
    },
  },
};
</script>

<style lang="less" scoped>
.class-menu {
  background: #fff;
  border-radius: 4px;
  padding-right: 20px;
  &-content {
    min-height: 300px;
    width: 190px;
  }
  &-item {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  &-title {
    font-size: 16px;
    color: #333333;
    width: 100px;
    padding: 10px;
  }
  &-title-120 {
    font-size: 16px;
    color: #333333;
    width: 120px;
    padding: 10px;
  }
  &-name {
    font-size: 14px;
    max-width: 60px;
  }
  &-add {
    color: #6049ff;
    font-size: 14px;
    margin-left: auto;
  }
  &-count {
    font-size: 14px;
    margin-left: 10px;
    color: #7f7f7f;
  }
  .class-menu-content {
    /deep/ .el-collapse {
      .el-collapse-item__content {
        padding-bottom: 0px;
      }
    }
    .class-menu-item {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.color-chose {
  color: #6049ff;
}
.color-chose-all {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #5a53f5;
  line-height: 32px;
}
.color-no-chose-all {
  color: #606266;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;

  line-height: 32px;
}
.item-bg-chose {
  background: #f0f6ff;
  border-radius: 2px;
}
.item-bg-chose-all {
  border-left: #5a53f5 solid 6px;
  height: 26px;
}
.item-bg-no-chose-all {
  border-left: #ffffff solid 6px;
  height: 26px;
}
.item-bg-no-chose {
  background: #ffffff;
  border-radius: 2px;
}
.color-no-chose {
  color: #606266;
}
.ml10 {
  margin-left: 10px;
}
.ml5 {
  margin-left: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
</style>
