<template>
  <!-- 上课时段管理 -->
  <div v-loading="allLoading" class="container-wrap">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :tableOptions="{
        height: tableHeight,
      }"
      @reset-form="resetFormCallback"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.screening") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          type="primary"
          @click="newAdd()"
          >{{ $t("el.common.new") }}</el-button
        >
      </template>

      <template slot="otherHandle" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="updateAttendClass(scope.row)"
          >{{ $t("el.common.edit") }}</el-button
        >
        <el-button
          size="small"
          type="text"
          @click="deleteAttendClass(scope.row)"
          >{{ $t("el.common.delete") }}</el-button
        >
      </template>
    </xm-table>
    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="visible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      append-to-body
      left
    >
      <el-form
        ref="dialogForm"
        label-width="120px"
        :model="dialogForm"
        :rules="rules"
      >
        <el-form-item
          :label="$t('el.planCourse.periodTime') + $t('el.common.colon')"
          prop="date"
        >
          <el-time-picker
            v-model="dialogForm.date"
            size="small"
            is-range
            arrow-control
            style="width: 320px"
            :range-separator="$t('el.common.to')"
            :start-placeholder="$t('el.common.startTime')"
            :end-placeholder="$t('el.common.endTime')"
            :placeholder="$t('el.planCourse.selectTimeRange')"
            value-format="HH:mm:00"
            format="HH:mm"
          />
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button size="small" @click="cancel()">{{
            $t("el.common.cancel")
          }}</el-button>
          <el-button
            type="primary"
            size="small"
            @click="submitForm('dialogForm')"
            class="mt20"
            >{{ $t("el.common.submit") }}</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  // 定义数据
  data() {
    return {
      tableHeight: undefined,
      dialogForm: {},
      visible: false,
      canCommit: true,
      allLoading: false,
      dialogTitle: "",
      rules: {
        schoolCode: [
          {
            required: true,
            message: this.$t("el.planCourse.selectSchool"),
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: this.$t("el.planCourse.pleaseSelectTimePeriod"),
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  // 计算属性
  computed: {
    ...mapGetters(["attendSchoolList"]),
    /**
     * 定义列
     */
    columns() {
      return [
        {
          label: this.$t("el.planCourse.classTimeNo"),
          prop: "classTime",
          formatter(row, column, value) {
            return (
              (row.startTime && row.startTime.substring(0, 5)) +
              "~" +
              (row.endTime && row.endTime.substring(0, 5))
            );
          },
        },
        // {
        //   label: this.$t("el.planCourse.school"),
        //   prop: "schoolName",
        // },
        // {
        //   label: '是否计分',
        //   prop: 'enable',
        //   formatter(row, column, value) {
        //     return (
        //       {
        //         0: '否',
        //         1: '是'
        //       }[value] || ''
        //     );
        //   }
        // },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "timerange",
          size: "small",
          prop: "date",
          prop1: "startTime",
          prop2: "endTime",
          width: 260,
          "value-format": "HH:mm:00",
          format: "HH:mm",
          label: this.$t("el.planCourse.classTimeNo"),
          labelWidth: "140px",
        },
      ];
    },
  },
  watch: {
    "dialogForm.date": {
      handler(val, old) {
        console.log(val, old);
        if (val !== null && val !== undefined) {
          if (val.length === 2) {
            if (!val[0]) {
              this.$set(this.dialogForm, "date", [
                moment().format("HH:mm:00"),
                moment(val[1]).format("HH:mm:00"),
              ]);
            } else if (!val[1]) {
              this.$set(this.dialogForm, "date", [
                moment(val[0]).format("HH:mm:00"),
                moment().format("HH:mm:00"),
              ]);
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    async init() {
      this.query();
    },
    /**
     * 筛选
     */
    query() {
      // console.log("this.$refs", this.$refs["xmTable"]);
      if (this.$refs["xmTable"]) {
        if (
          new Date(
            "2021/12/17 " + this.$refs["xmTable"].getNowQueryParams.endTime
          ).getTime() <
          new Date(
            "2021/12/17 " + this.$refs["xmTable"].getNowQueryParams.startTime
          ).getTime()
        ) {
          this.$message.error("开始时间不能大于结束结束时间，请重新选择");
          return;
        }
        this.$refs["xmTable"].query();
      }
    },
    // 新建
    newAdd() {
      this.dialogForm = {};
      this.dialogTitle = this.$t("el.planCourse.newPeriodTime");

      this.canCommit = true;
      this.visible = true;
      if (this.$refs["dialogForm"]) {
        this.$nextTick(() => {
          this.$refs["dialogForm"].clearValidate();
        });
      }
    },

    /**
     * 重置
     */
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },

    resetFormCallback(queryParams) {
      // console.log('queryParams',queryParams);
    },

    // 点击编辑
    updateAttendClass(row) {
      this.dialogForm = row;
      this.dialogTitle = this.$t("el.planCourse.editTime");
      if (this.dialogForm.startTime && this.dialogForm.endTime) {
        // this.dialogForm.date = [
        //   this.dialogForm.startTime,
        //   this.dialogForm.endTime,
        // ];
        this.$set(this.dialogForm, "date", [
          this.dialogForm.startTime,
          this.dialogForm.endTime,
        ]);
      }
      this.canCommit = true;
      this.visible = true;
    },

    // 点击删除
    deleteAttendClass(row) {
      this.$confirm(
        this.$t("el.planCourse.deleteClassTime"),
        this.$t("el.common.prompt"),
        {
          confirmButtonText: this.$t("el.common.confirm"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$store
            .dispatch("deletePeriod", row.id)
            .then((result) => {
              if (result.success) {
                this.$message.success(
                  this.$t("el.classManage.deleteSuccess") + "！"
                );
                this.query();
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch(() => {
              // this.$message.error("删除失败！" + err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("el.courseClassManage.cancelRemove"),
          });
        });
    },

    submitForm(formName) {
      if (this.canCommit) {
        this.canCommit = false;
        this.allLoading = true;
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            console.log("dialogForm", this.dialogForm);
            let params = {};
            if (this.dialogForm.date) {
              params.startTime = this.dialogForm.date[0];
              params.endTime = this.dialogForm.date[1];
            }
            params.schoolCode = this.dialogForm.schoolCode;
            // 编辑
            if (this.dialogForm.id) {
              params.id = this.dialogForm.id;
              await this.updatePeriod(params);
            } else {
              await this.addPeriod(params);
            }
            this.canCommit = true;
            this.allLoading = false;
          } else {
            this.canCommit = true;
            this.allLoading = false;
            return false;
          }
        });
      }
    },
    // 编辑上课时段
    updatePeriod(params) {
      return this.$store
        .dispatch("updatePeriod", this.handparams(params))
        .then((result) => {
          if (result.success) {
            this.visible = false;
            this.$message.success(this.$t("el.classManage.editSuccess") + "！");
            this.query();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    // 新增上课时段
    addPeriod(params) {
      return this.$store
        .dispatch("addPeriod", this.handparams(params))
        .then((result) => {
          console.log("result", result);
          if (result.success) {
            this.$message.success(this.$t("el.classManage.addSuccess") + "！");
            this.visible = false;
            this.query();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    cancel() {
      this.visible = false;
    },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      console.log("params", params);
      if (!params.date) {
        params.startTime = params.endTime = "";
      }
      let data = {
        schoolCode: params.schoolCode,
        startTime: params.startTime,
        endTime: params.endTime,
        offset: params.offset,
        limit: params.limit,
      };

      return this.$store
        .dispatch("getClassTimePage", this.handparams(data))
        .then((result) => {
          //   console.log("result",result);
          return result;
        });
    },

    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.container-wrap {
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 10px;

  /deep/ .el-button--small,
  .el-button--small.is-round {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/deep/ .el-dialog {
  margin-top: 24 px !important;
  margin-bottom: 24 px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
}
</style>
