<template>
  <!-- 班级管理 -->
  <div v-loading="allLoading" class="container-content">
    <router-menu-title
      :routerTitleList="routerTitleList"
      last-route="/planCourseManage/courseManage"
    ></router-menu-title>
    <div class="course-title flex">
      <div class="flex course-title-course">
        <span style="white-space: nowrap">{{
          $t("el.planCourse.CourseName") + $t("el.common.colon")
        }}</span>
        <span class="course-title-course-name">{{ courseName }}</span>
      </div>
      <!-- <div class="flex course-title-course ml40">
        <span style="white-space: nowrap">{{
          $t("el.planCourse.school") + $t("el.common.colon")
        }}</span>
        <span class="course-title-course-name">{{ schoolName }}</span>
      </div> -->
    </div>

    <div class="class-content flex">
      <!-- 左边班级列表 -->
      <class-tab-menu
        @add-class="onHandleAddClass"
        @edit-menu-item="editMenuItem"
        @delete-menu-item="deleteMenuItem"
        @handle-menu-item="onHandleMenuItem"
        @all-chose="onHandleMenuChose"
        :classList="classListNew"
        :activeNamesPar="activeNamesPar"
        :currentClassId="currentClassId"
      ></class-tab-menu>
      <!-- 左边学生列表 -->
      <div class="right-list">
        <xm-table
          ref="xmTable"
          :fatch-method="fatchMethod"
          :form-items="formItems"
          :columns="columns"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :autoLoad="false"
          :tableOptions="{
            height: tableHeight,
          }"
        >
          <template slot="formBtn">
            <el-button
              type="primary"
              class="teachManage-tool-btnicon"
              @click="addStudent()"
              >{{ $t("el.courseClassManage.addStudent") }}</el-button
            >
          </template>

          <template slot="otherHandle" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="changeStudentDialog(scope.row)"
              >{{ $t("el.common.ChangeClass") }}</el-button
            >
            <el-button
              size="small"
              type="text"
              @click="deleteAttendClass(scope.row)"
              >{{ $t("el.courseClassManage.remove") }}
            </el-button>
          </template>
        </xm-table>
      </div>

      <!-- 新增班级弹窗 -->
      <c-dialog
        v-if="showClassDialog"
        ref="dialogClass"
        :formItems="formDialogItems"
        :formOptions="dialogFormOptions"
        :visible="showClassDialog"
        :optionsData="optionsData"
        @submit-form="submitForm"
        @close-dialog="closeClassDialog"
      >
      </c-dialog>
      <!-- 学生弹框 -->
      <c-dialog
        ref="dialogStudent"
        :formItems="studentFormItems"
        :formOptions="studentFormOptions"
        :visible="showStudentDialog"
        :optionsData="optionsData"
        @submit-form="submitStudentForm"
        @close-dialog="closeStudentDialog"
        @onSelectChange="onSelectChange"
      >
      </c-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import routerMenuTitle from "../../../components/biz-components/router-menu-title/index.vue";
import classTabMenu from "../../../components/biz-components/class-tab-menu/index.vue";
import CDialog from "../../../components/biz-components/CDialog/index.vue";
import {
  // getLast2SchoolYear,
  getSemesterClassList,
} from "@/api/manage/classManage.js";
import { getschoolYearListTwo } from "@/api/manage/select-course";
import { classTree } from "@/api/home/teachmanage";
export default {
  // 定义数据
  data() {
    return {
      tableHeight: undefined,
      schoolList: [],
      classList: [], // 班级列表 == 平铺无层级
      classListNew: [], // 班级列表 == 有层级
      activeNamesPar: [], // 班级列表 - 默认展开的数据
      canCommit: true,
      allLoading: false,
      dialogTitle: "",
      fromTitle: "",
      showClassDialog: false, // 新增班级弹框
      showStudentDialog: false, // 学生弹框
      optionsData: {}, // 弹框默认数据
      schoolName: "",
      courseName: "",
      courseId: "",
      tenantInfoId: "",
      schoolCode: "",
      currentClassId: null, // 当前选中的班级ID
      studentTree: [], // 班级学生树
      dialogType: 0, // 0: 班级弹框  1： 调班弹框
      originalClassId: "", // 原classId

      formOptions: {},

      dialogItems: [],
      academicYearOptions: [],
      semesterOptions: [],
      semesterClassList: [],
      selectedYearOption: [],
    };
  },
  async created() {
    this.init();
  },
  components: {
    routerMenuTitle,
    classTabMenu,
    CDialog,
  },
  // 计算属性
  computed: {
    ...mapGetters(["attendSchoolList"]),
    schoolYearOptions() {
      // 学年下拉框，只取近两年学年数据
      return this.academicYearOptions;
    },
    changeFormOptions() {
      return {
        // 班级弹框配置
        rules: {
          classId: [
            {
              required: true,
              message: this.$t("el.courseClassManage.classRules"),
              trigger: "change",
            },
          ],
        },
      };
    },
    newFormOptions() {
      return {
        // 班级弹框配置
        rules: {
          className: [
            {
              required: true,
              message: this.$t("el.classManage.enterClassName"),
              trigger: "blur",
            },
            {
              min: 1,
              max: 10,
              message: this.$t("el.planCourse.ContainsCharacters"),
              trigger: "blur",
            },
          ],
          academicYearAndSemester: [
            {
              required: true,
              trigger: "change",
              validator: (rule, value, callback) => {
                if (value) {
                  if (!value.schoolYear) {
                    callback(
                      new Error(this.$t("el.planCourse.academicYearSelect"))
                    );
                  } else if (!value.term) {
                    callback(
                      new Error(this.$t("el.planCourse.semesterSelect"))
                    );
                  } else {
                    callback();
                  }
                } else {
                  callback(
                    new Error(
                      this.$t("el.planCourse.academicYearAndSemesterSelect")
                    )
                  );
                }
              },
            },
          ],
          maxStudentNum: [
            {
              required: true,
              message: this.$t("el.infrastructure.enterNumberStudents"),
              trigger: "blur",
            },
            {
              type: "number",
              min: 1,
              max: 1000,
              message: this.$t("el.planCourse.MoreThan0"),
              trigger: "blur",
              required: true,
              validator: (rule, value, callback) => {
                if ((value != null && value !== "") || value === 0) {
                  if (
                    /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value) ===
                      false ||
                    value === 0
                  ) {
                    callback(new Error(this.$t("el.planCourse.MoreThan0")));
                  } else {
                    callback();
                  }
                } else {
                  callback();
                }
              },
            },
          ],
        },
      };
    },
    studentFormOptions() {
      return {
        // 学生表单弹框配置
        rules: {
          classId: [
            {
              required: true,
              message: this.$t("el.courseClassManage.classRules"),
              trigger: "change",
            },
          ],
          studentIds: [
            {
              required: true,
              message: this.$t("el.courseClassManage.studentRules"),
              trigger: "change",
            },
          ],
        },
      };
    },

    classFormItems() {
      return [
        {
          itemType: "text",
          prop: "courseName",
          label: this.$t("el.courseClassManage.course"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
        },
        {
          itemType: "input",
          prop: "className",
          placeholder: this.$t("el.classManage.enterClassName"),
          label: this.$t("el.courseClassManage.class"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
        },
        {
          itemType: "selectMulti",
          prop: "academicYearAndSemester",
          label: this.$t("el.planCourse.academicYearAndSemester"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:160px;",
          maxlength: 10,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
          multi: [
            {
              prop: "schoolYear",
              placeholder: this.$t("el.planCourse.academicYearSelect"),
              options: [...this.selectedYearOption, ...this.schoolYearOptions],
            },
            {
              prop: "term",
              placeholder: this.$t("el.planCourse.semesterSelect"),
              options: [...this.termOptions],
            },
          ],
        },
        {
          itemType: "inputNumber",
          prop: "maxStudentNum",
          max: 1000,
          min: 1,
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseClassManage.mostStudentsNumber"),
          labelWidth: this.$t("el.common.width120_220"),
          itemStyle: "width:220px;",
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
        },
      ];
    },

    exchangeClassFormList() {
      return [
        // {
        //   itemType: "text",
        //   prop: "studentCode",
        //   label: this.$t("el.courseClassManage.studentID"),
        //   labelWidth: "140px",
        //   clearable: true,
        //   itemStyle: "width:220px;",
        //   maxlength: 10,
        //   formStyle: "padding-left:60px;box-sizing: border-box;",
        // },
        {
          itemType: "text",
          prop: "studentName",
          label: this.$t("el.courseClassManage.studentName"),
          labelWidth: "140px",
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "padding-left:60px;box-sizing: border-box;",
        },
        {
          itemType: "text",
          prop: "className",
          label: this.$t("el.courseClassManage.currentClass"),
          labelWidth: "140px",
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
        },
        {
          itemType: "select",
          prop: "classId",
          placeholder: this.$t("el.courseClassManage.classRules"),
          label: this.$t("el.courseClassManage.callInClass"),
          options: this.semesterClassList,
          labelWidth: "140px",
          clearable: true,
          itemStyle: "width:260px;",
          maxlength: 10,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
        },
      ];
    },

    rules() {
      return {
        schoolCode: [
          {
            required: true,
            message: this.$t("el.planCourse.selectSchool"),
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: this.$t("el.planCourse.pleaseSelectTimePeriod"),
            trigger: "change",
          },
        ],
      };
    },

    routerTitleList() {
      return [
        {
          name: this.$t("el.planCourse.courseManagement"),
        },
        {
          name: this.$t(this.$route.meta.title),
        },
      ];
    },
    /**
     * 定义列
     */
    columns() {
      return [
        {
          label: this.$t("el.courseClassManage.studentName"),
          prop: "studentName",
        },
        // {
        //   label: this.$t("el.courseClassManage.studentID"),
        //   prop: "studentCode",
        //   lineRule: true,
        //   showtooltip: "false", // 传字符串‘false’时候不显示默认的
        //   width: 80,
        // },
        {
          label: this.$t("el.courseClassManage.studentNumber"),
          prop: "studentNumber",
          width: 110,
        },
        // {
        //   label: this.$t("el.schoolResourceManage.school"),
        //   prop: "schoolName",
        //   lineRule: true,
        //   showtooltip: "false", // 传字符串‘false’时候不显示默认的
        // },
        {
          label: this.$t("el.courseClassManage.gradeName"),
          prop: "gradeName",
        },
        {
          label: this.$t("el.courseClassManage.schoolClassName"),
          prop: "schoolClassName",
          width: 120,
        },
        {
          label: this.$t("el.courseClassManage.currentClass"),
          prop: "className",
        },
        {
          label: this.$t("el.courseClassManage.createTime"),
          prop: "createTime",
          lineRule: true,
          showtooltip: "false", // 传字符串‘false’时候不显示默认的
          width: 100,
          // formatter(row, column, value) {
          //   return row.startTime + "~" + row.endTime;
          // },
        },
        // {
        //   label: '是否计分',
        //   prop: 'enable',
        //   formatter(row, column, value) {
        //     return (
        //       {
        //         0: '否',
        //         1: '是'
        //       }[value] || ''
        //     );
        //   }
        // },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          width: this.$t("el.common.width100_160"),
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "inputSearch",
          prop: "keyword",
          placeholder: this.$t("el.courseClassManage.enterNameStudentID"),
          label: "",
          clearable: true,

          formStyle: "margin-left:auto;",
          itemStyle: `width:${this.$t("el.common.width220_340")};`,
        },
      ];
    },

    dialogFormOptions() {
      return this.formOptions;
    },
    // dialog 表单
    formDialogItems() {
      return this.dialogItems;
    },
    // 学生表单item
    studentFormItems() {
      return [
        {
          itemType: "select",
          prop: "classId",
          placeholder: this.$t("el.courseClassManage.classRules"),
          label: this.$t("el.courseClassManage.chooseClass"),
          options: this.classList,
          labelWidth: "130px",
          clearable: false,
          itemStyle: "width:260px;",
          maxlength: 10,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
        },
        {
          itemType: "cascader",
          prop: "studentIds",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseClassManage.chooseStudent"),
          labelWidth: "130px",
          itemStyle: "width:260px;",
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
          props: {
            // value: "code",
            // label: "name",
            // children: "children",
            multiple: true,
          },
          options: this.studentTree,
        },
      ];
    },

    ...mapState({
      // 学年下拉数据 取自应用运维端的字典配置
      // schoolYearOptions(state) {
      //   console.log("state.dict======", state.dict);
      //   return state.dict.SCHOOL_YEAR.map((item) => ({
      //     label: item.dictionaryText,
      //     value: item.dictionaryKey,
      //   }));
      // },
      // 学期下拉数据
      termOptions(state) {
        return state.dict.TERM.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
    }),
  },
  methods: {
    async init() {
      this.courseId = this.$route.query.courseId || "";
      this.tenantInfoId = this.$route.query.tenantInfoId || "";
      this.schoolCode = this.$route.query.schoolCode || "";
      this.schoolName = this.$route.query.schoolName || "";
      this.courseName = this.$route.query.courseName || "";
      this.currentClassId = Number(this.$route.query.classId) || null;
      this.getClassInfoList(this.schoolCode, this.tenantInfoId);
      this.getClassInfoListNew(this.tenantInfoId);
      this.academicYearOptions = [];
      this.semesterOptions = [];
      this.getLast2SchoolYear();
      this.$store.state.dict.TERM.forEach((el) => {
        this.semesterOptions.push({
          label: el.dictionaryText,
          value: el.dictionaryKey,
        });
      });

      this.$nextTick(() => {
        this.onHandleMenuChose("init");
      });
      // this.query();
    },
    /**
     * 筛选
     */
    query() {
      this.$refs["xmTable"].query();
    },
    // 新建学生
    async addStudent() {
      this.optionsData = {
        dialogTitle: this.$t("el.courseClassManage.addStudent"),
      };
      this.canCommit = true;
      this.showStudentDialog = true;
      const studentFormRef = this.$refs["dialogStudent"];
      if (this.currentClassId) {
        studentFormRef.queryParams = {
          classId: this.currentClassId,
        };
      } else {
        let classId = this.classList[0] && this.classList[0].id;
        studentFormRef.queryParams = {
          classId: classId,
          // studentIds: [ ["101101","101101-1","101101-1-1","101101-1-1-5"] ]
        };
      }
      this.$nextTick(() => {
        studentFormRef.$refs["form"].clearValidate();
      });
      // bugfix: 只有选择了班级 才需获取学生列表
      if (!studentFormRef.queryParams?.classId) return;
      await this.getCourseClassTree();
      this.getSelectedStudentList();
    },
    // select change 切换
    async onSelectChange(item, id) {
      if (item.prop === "classId") {
        this.studentTree = [];
        this.$nextTick(() => {
          this.$set(this.$refs["dialogStudent"].queryParams, "studentIds", []);
        });
        await this.getCourseClassTree();
        this.getSelectedStudentList();
      }
    },
    // 获取 学期下的班级
    getSemesterClassList(schoolCode, courseId, schoolYear, term, classId) {
      getSemesterClassList({
        schoolCode: schoolCode,
        courseId: courseId,
        schoolYear: schoolYear,
        term: term,
        classId: classId,
      })
        .then((result) => {
          if (result.success && result.data) {
            result.data.forEach((element) => {
              element.label = element.className;
              element.value = element.id;
            });
            this.semesterClassList = result.data;
            this.dialogItems = this.exchangeClassFormList;
          } else {
            this.semesterClassList = [];
          }
        })
        .catch(() => {
          this.semesterClassList = [];
        });
    },
    // 默认选中的学生数据
    getSelectedStudentList() {
      return this.$store
        .dispatch("getSelectedStudentList", {
          schoolCode: this.schoolCode,
          courseId: this.courseId,
          classId: this.$refs["dialogStudent"].queryParams.classId,
        })
        .then((result) => {
          if (result.data && result.data instanceof Array) {
            let defaultChoseStudent = [];
            result.data.forEach((element) => {
              let a = [];
              a.push(element.code2);
              a.push(element.code3);
              a.push(element.code4);
              defaultChoseStudent.push(a);
            });
            this.$nextTick(() => {
              this.$set(
                this.$refs["dialogStudent"].queryParams,
                "studentIds",
                defaultChoseStudent
              );
              // this.$refs["dialogStudent"].queryParams.studentIds = defaultChoseStudent;
            });
          }
        })
        .catch(() => {});
    },

    // 学生树
    getCourseClassTree() {
      return this.$store
        .dispatch("getCourseClassTree", {
          schoolCode: this.schoolCode,
          courseId: this.courseId,
          classId: this.$refs["dialogStudent"].queryParams.classId,
        })
        .then((result) => {
          if (result.data && result.data instanceof Array) {
            // FIXME: 通过浅拷贝来实现数据更新
            this.convertTree(result.data);
            this.studentTree = result.data;
          } else {
            this.studentTree = [];
          }
        })
        .catch(() => {
          this.studentTree = [];
        });
    },
    convertTree(data) {
      data.forEach((element) => {
        element.label = element.name;
        element.value = element.code;

        if (element.children && element.children.length > 0) {
          this.convertTree(element.children);
          const levelCONF = element.level + 1;
          const enableAll = element.children.every(
            (child) => child.enable === true && child.level === levelCONF
          );
          element.enable = enableAll;
          // element.disabled = enableAll;
        } else {
          if (
            element.level === 3 ||
            element.level === 2 ||
            element.level === 1
          ) {
            element.disabled = true;
          }
        }
        if (element.level === 4) {
          if (element.enable) {
            element.disabled = true;
          } else {
            element.disabled = false;
          }
        }
      });
    },
    // 新建班级
    onHandleAddClass() {
      this.optionsData = {
        dialogTitle: this.$t("el.courseClassManage.addClass"),
        width: this.$i18n.locale === "zh_CN" ? "600px" : "800px",
      };
      this.dialogType = 0;
      this.dialogItems = this.classFormItems;
      this.canCommit = true;
      this.showClassDialog = true;
      this.formOptions = {};
      this.$nextTick(() => {
        this.formOptions = this.newFormOptions;
        this.$refs["dialogClass"].queryParams.courseName = this.courseName;
        this.$refs["dialogClass"].queryParams.academicYearAndSemester = {
          schoolYear: "",
          term: "",
        };
        this.$refs["dialogClass"].$refs["form"].clearValidate();
      });
    },

    // 弹出调班框
    changeStudentDialog(row) {
      this.optionsData = {
        dialogTitle: this.$t("el.common.ChangeClass"),
      };
      this.dialogType = 1;
      this.originalClassId = row.classId;
      // this.exchangeClassFormList[3].options = this.classList;
      this.formOptions = this.changeFormOptions;
      this.canCommit = true;
      this.showClassDialog = true;
      this.getSemesterClassList(
        row.schoolCode,
        this.tenantInfoId,
        row.schoolYear,
        row.term,
        row.classId
      );
      this.$nextTick(() => {
        let data = { ...row };
        this.$refs["dialogClass"].queryParams = data;
        this.$set(this.$refs["dialogClass"].queryParams, "classId", "");
      });
    },

    // 编辑班级
    editMenuItem(item, fullSchoolYear) {
      if (this.academicYearOptions.every((i) => i.value !== item.schoolYear)) {
        this.selectedYearOption = [
          { label: fullSchoolYear.slice(0, 11), value: item.schoolYear },
        ];
      }

      this.optionsData = {
        dialogTitle: this.$t("el.classManage.editClass"),
        width: this.$i18n.locale === "zh_CN" ? "600px" : "800px",
      };
      this.dialogType = 0;
      this.dialogItems = this.classFormItems;
      this.formOptions = this.newFormOptions;
      this.canCommit = true;
      this.showClassDialog = true;
      this.$nextTick(() => {
        this.$refs["dialogClass"].queryParams = {
          id: item.id,
          courseName: this.courseName,
          className: item.className,
          questionnaireSchool: item.questionnaireSchool,
          academicYearAndSemester: {
            schoolYear: item.schoolYear,
            term: item.term * 1,
          },
          maxStudentNum: item.maxStudentNum,
        };
      });
    },
    // 删除班级
    deleteMenuItem(item) {
      this.$confirm(
        this.$t("el.courseClassManage.determineName") + `${item.className}`,
        this.$t("el.common.prompt"),
        {
          confirmButtonText: this.$t("el.common.confirm"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$store
            .dispatch("deleteClassInfo", item.id)
            .then((result) => {
              if (result.success) {
                this.$message.success(
                  this.$t("el.classManage.deleteSuccess") + "！"
                );
                // this.currentClassId = "";
                this.onHandleMenuChose();
                this.getClassInfoList(this.schoolCode, this.tenantInfoId);
                this.getClassInfoListNew(this.tenantInfoId);
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch(() => {
              // this.$message.error("删除失败！" + err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("el.courseClassManage.cancelRemove"),
          });
        });
    },
    // 移除学生
    deleteAttendClass(item) {
      this.$confirm(
        this.$t("el.courseClassManage.confirmThat") +
          `${item.studentName}` +
          this.$t("el.courseClassManage.confirmContext"),
        this.$t("el.common.prompt"),
        {
          confirmButtonText: this.$t("el.courseClassManage.remove"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$store
            .dispatch("deleteStudentClass", item.id)
            .then(async (result) => {
              if (result.success) {
                this.$message.success(
                  this.$t("el.courseClassManage.removeSuccess")
                );
                this.$refs["xmTable"].query();
                await this.getClassInfoList(this.schoolCode, this.tenantInfoId);
                await this.getClassInfoListNew(this.tenantInfoId);
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch(() => {
              // this.$message.error("删除失败！" + err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("el.courseClassManage.cancelRemove"),
          });
        });
    },

    // 选中班级
    onHandleMenuItem(item) {
      this.currentClassId = item.id;
      this.$refs["xmTable"].queryParams.classId = item.id;
      this.$refs["xmTable"].query();
    },
    // 选中全部
    onHandleMenuChose(type) {
      !type ? (this.currentClassId = null) : "";
      this.$refs["xmTable"].queryParams.classId = this.currentClassId;
      this.$refs["xmTable"].query();
    },
    // 关闭dialog
    closeClassDialog() {
      this.selectedYearOption = [];
      this.showClassDialog = false;
    },
    // 关闭 学生弹框
    closeStudentDialog() {
      this.showStudentDialog = false;
    },

    // 获取学校列表 == 平铺 无层级
    async getClassInfoList(schoolCode, courseId) {
      this.activeNamesPar = [];
      await this.$store
        .dispatch("getClassInfoList", { schoolCode, courseId })
        .then((result) => {
          if (result && result.data instanceof Array) {
            this.classList = result.data;
            this.classList.forEach((element) => {
              element.value = element.id;
              element.label = element.className;
            });
            if (this.currentClassId) {
              // 是否能匹配到对应的课程班id
              const queryClassIdData = this.classList.filter(
                (item) => item.value === this.currentClassId
              );
              if (!queryClassIdData.length) this.currentClassId = null;
            }
          } else {
            this.classList = [];
          }
        })
        .catch(() => {
          this.classList = [];
        });
    },
    // 获取学校列表 == 有层级
    async getClassInfoListNew(courseId) {
      this.activeNamesPar = [];
      await this.$store
        .dispatch("getClassInfoListNew", { courseId })
        .then((result) => {
          if (result && result.data instanceof Array) {
            this.classListNew = result.data;
            this.classListNew.forEach((element) => {
              this.activeNamesPar.push(element.schoolYear);
              element.contents.forEach((el) => {
                el.value = el.id;
                el.label = el.className;
              });
            });
          } else {
            this.classListNew = [];
          }
        })
        .catch(() => {
          this.classListNew = [];
        });
    },

    // 新建班级提交
    submitForm(refFormName, dialogForm) {
      if (this.canCommit) {
        this.canCommit = false;
        this.allLoading = true;

        refFormName.validate(async (valid) => {
          if (valid) {
            if (this.dialogType === 0) {
              // 班级弹框
              let params = {
                schoolCode: this.schoolCode,
                courseId: this.courseId,
                courseTenantId: this.$route.query.tenantId,
                courseTenantType: this.$route.query.tenantType,
                courseTenantInfoId: this.$route.query.tenantInfoId,
                originTenantId: this.$route.query.originTenantId,
                schoolYear: dialogForm.academicYearAndSemester.schoolYear,
                term: dialogForm.academicYearAndSemester.term,
                ...dialogForm,
              };
              delete params.academicYearAndSemester;
              // 编辑
              if (dialogForm.id) {
                params.id = dialogForm.id;
                await this.updateClassInfo(params);
              } else {
                await this.addClassInfo(params);
              }
            } else {
              // 调班弹框
              let params = {
                // schoolCode: this.schoolCode,
                // courseId: this.courseId,
                id: dialogForm.id,
                studentId: dialogForm.studentId,
                classId: dialogForm.classId,
                originalClassId: this.originalClassId,
              };
              try {
                await this.updateStudentClass(params);
                await this.getClassInfoList(this.schoolCode, this.tenantInfoId);
                await this.getClassInfoListNew(this.tenantInfoId);
              } catch (error) {
                //
              }
            }

            this.canCommit = true;
            this.allLoading = false;
          } else {
            this.canCommit = true;
            this.allLoading = false;
            return false;
          }
        });
      }
    },
    // 新增学生
    submitStudentForm(refFormName, dialogForm) {
      if (this.canCommit) {
        this.canCommit = false;
        this.allLoading = true;
        refFormName.validate(async (valid) => {
          if (valid) {
            // dialogForm.studentIds = [dialogForm.studentIds[0][3]];
            let studentIds = [];
            if (dialogForm.studentIds.length > 0) {
              dialogForm.studentIds.forEach((element) => {
                studentIds.push(element[2]);
              });
            }
            let params = {
              classId: dialogForm.classId,
              studentIds: studentIds,
              schoolCode: this.schoolCode,
              courseId: this.courseId,
            };

            await this.addCourseStudent(params);
            await this.getClassInfoList(this.schoolCode, this.tenantInfoId);
            await this.getClassInfoListNew(this.tenantInfoId);
            this.canCommit = true;
            this.allLoading = false;
          } else {
            this.canCommit = true;
            this.allLoading = false;
            return false;
          }
        });
      }
    },
    // 新增学生
    addCourseStudent(params) {
      return this.$store
        .dispatch("addStudentClass", this.handparams(params))
        .then((result) => {
          if (result.success) {
            this.$message.success(this.$t("el.courseClassManage.addSuccess"));
            this.$refs["dialogStudent"].closeDialog();
            this.query();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 编辑班级
    updateClassInfo(params) {
      return this.$store
        .dispatch("updateClassInfo", this.handparams(params))
        .then((result) => {
          if (result.success) {
            this.$refs["dialogClass"].closeDialog();
            this.$message.success(this.$t("el.classManage.editSuccess") + "!");
            this.getClassInfoList(this.schoolCode, this.tenantInfoId);
            this.getClassInfoListNew(this.tenantInfoId);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    // 调班
    updateStudentClass(params) {
      return this.$store
        .dispatch("updateStudentClass", this.handparams(params))
        .then((result) => {
          if (result.success) {
            this.$refs["dialogClass"].closeDialog();
            this.$message.success(
              this.$t("el.planCourse.ChangeSuccessfully") + "！"
            );
            this.$refs["xmTable"].query();
          }
        });
    },

    // 新增班级
    addClassInfo(params) {
      return this.$store
        .dispatch("addClassInfo", this.handparams(params))
        .then((result) => {
          if (result.success) {
            this.$message.success(this.$t("el.courseClassManage.addSuccess"));
            this.$refs["dialogClass"].closeDialog();
            this.getClassInfoList(this.schoolCode, this.tenantInfoId);
            this.getClassInfoListNew(this.tenantInfoId);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      let data = {
        schoolCode: this.schoolCode,
        courseId: this.tenantInfoId,
        classId: params.classId,
        keyword: params.keyword,
        offset: params.offset,
        limit: params.limit,
      };
      return this.$store
        .dispatch("getCourseClassPage", this.handparams(data))
        .then((result) => {
          return result;
        });
    },

    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    getLast2SchoolYear() {
      this.academicYearOptions = [];
      getschoolYearListTwo().then(({ status, data }) => {
        if (status === 200) {
          data.forEach((el) => {
            this.academicYearOptions.push({
              label: el.name,
              value: el.id,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container-content {
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 10px;
  width: fit-content;

  .class-content {
    padding-top: 20px;
    .right-list {
      width: 100%;
      /deep/ .el-form--inline {
        display: flex;
        // flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
        .el-form-item:nth-child(1) {
          margin-left: 0 !important;
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 10px;
      }
    }
  }

  .course-title {
    background: #fff;
    width: 1000px;
    &-school {
      font-size: 18px;
      margin-top: 10px;
      &-name {
        width: 100%;
        word-break: break-all;
      }
    }
    &-course {
      font-size: 16px;
      margin-top: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #394756;
      line-height: 32px;
      &-name {
        width: 100%;
        word-break: break-all;
      }
    }
  }

  /deep/ .el-button--small,
  .el-button--small.is-round {
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
  }
}
/deep/ .el-dialog {
  margin-top: 24 px !important;
  margin-bottom: 24 px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
}
/deep/ .el-cascader__tags .el-tag .el-icon-close {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background-color: #c0c4cc;
  color: #fff;
  display: none;
}
.ml40 {
  margin-left: 40px;
}
</style>
