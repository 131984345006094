/**
 * 选课管理
 */
import axios from "@/plugins/axios.js";

/**
 * 新增选课任务
 * @return {Promise}
 */
export function addSelectCourseTask(data) {
  return axios.post("/course/choose/task/add", data);
}

/**
 * 查询选课任务列表
 * @return {Promise}
 */
export function getSelectCourseTaskList(params) {
  return axios.get("/course/choose/task/page", { params });
}

/**
 * 查询单条
 * @return {Promise}
 */
export function getTaskItem(id) {
  return axios.get(`/course/choose/task/info/${id}`);
}

/**
 * 删除
 * @return {Promise}
 */
export function delectTaskItem(id) {
  return axios.delete(`/course/choose/task/delete/${id}`);
}

/**
 * 编辑
 * @return {Promise}
 */
export function updateSelectCourseTask(data) {
  return axios.put("/course/choose/task/update", data);
}

/**
 * 编辑移除
 * @return {Promise}
 */
export function removeCourseItem(taskId, courseId) {
  return axios.delete(`/course/choose/task/remove/${taskId}/${courseId}`);
}

/**
 * 查看选课结果分页
 * @return {Promise}
 */
export function getCourseTaskResult(params) {
  return axios.get("/course/choose/task/result/page", { params });
}

/**
 * 课程全部类别列表
 * @return {Promise}
 */
export function getCourseTypeTreeList() {
  return axios.get("/course/choose/task/treeList");
}

/**
 * 获取年级
 * @return {Promise}
 */
export function getClasslist(id) {
  return axios.get("/course/choose/task/grade");
}

/**
 * 获取sys年级
 * @return {Promise}
 */
export function getClassSyslist(id) {
  return axios.get("/course/choose/task/sysGrade");
}
/**
 * 获取学年
 * @return {Promise}
 */
export function getschoolYearList() {
  return axios.get("/course/choose/task/schoolYearList");
}
/**
 * 获取学年  最近2年
 * @return {Promise}
 */
export function getschoolYearListTwo() {
  return axios.get("/course/choose/task/schoolYearList/forTwoYears");
}
/**
 * 获取学期
 * @return {Promise}
 */
export function getTermList() {
  return axios.get("/course/choose/task/termList");
}

export function getAllGradeList() {
  return axios.get("/course/choose/task/sysGrade");
}

// 获取课程类别列表
export function getCourseCategory() {
  return axios.get("/course/choose/task/categoryList");
}

// 获取科目列表
export function getCourseSubject() {
  return axios.get("/course/choose/task/subjectList");
}

export function deleteTaskItem(id) {
  return axios.delete(`/course/choose/task/delete/${id}`);
}

export function getResultList(params) {
  return axios.get("/course/choose/task/result/page", { params });
}

export function getClassListByGradeId(id) {
  return axios.get(`/course/choose/task/class/${id}`);
}

export function 校验收款方(params) {
  return axios.get(`/course/choose/task/checkCharge`, { params });
}

export function 课程可用时段列表(params) {
  return axios.get(`/course/choose/task/periodsList`, { params });
}
