<template>
  <el-dialog
    :visible="dialogVisible"
    :before-close="handleBeforeClose"
    width="400px"
    custom-class="user-batch-import-dialog"
    :show-close="false"
  >
    <div>
      <div class="loading">
        <span class="el-icon-loading"></span>
        <span class="text">批量导入授权中</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 关闭前
    handleBeforeClose() {
      this.$emit("beforeClose");
    },
  },
};
</script>

<style lang="less" scoped>
.user-batch-import-dialog {
  .loading {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    .el-icon-loading {
      font-size: 40px;
      color: #5a53f5;
    }
    .text {
      font-size: 14px;
      margin-top: 15px;
    }
  }
}
</style>
